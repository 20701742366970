import React, { FC, memo } from 'react'
import Dialog from '@ucheba/ui/components/Dialog/desktop/Dialog'
import styled from 'styled-components'
import { IMessengerDialogProps } from '../types'
import ApplicationFormMessengers from '../../AppliactionForm/desktop/ApplicationFormMessengers'
import SC from '../../AppliactionForm/base/ApplicationForm.styles'

const { Block: BlockSC } = SC
const Block = styled(BlockSC)``
const MessengersDialog: FC<IMessengerDialogProps> = (props) => {
  const { open, onClose } = props

  return (
    <Dialog open={open} onClose={onClose} overflow='auto' spacing='xlarge' size='small'>
      <Block>
        <ApplicationFormMessengers />
      </Block>
    </Dialog>
  )
}

export default memo(MessengersDialog)
